import React from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store } from "./redux/store";
import BrandData from "./services/BrandData";

const persistor = persistStore(store);

const wrapper = ({ element }) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    let loc = document.location;
    window.dataLayer.push({
      originalLocation: loc.href,
    });
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
        <BrandData />
      </PersistGate>
    </Provider>
  );
};

export default wrapper;
